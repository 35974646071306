import React from 'react'
import '../OurTeam/Team.css'
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";

const Team = () => {
  return (
    <>
      <section id="team" className="team section-bg">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
          <div class="ribbon3">Our Team</div>
            <p>Meet the dedicated professionals behind Medix. Our team is committed to providing exceptional medical products and services, ensuring the highest standards of healthcare.</p>
          </div>
          <div className="row">
            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay={100}>
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80" className="img-fluid" alt="Aarav Patel" />
                </div>
                <div className="member-info">
                  <h4>Aarav Patel</h4>
                  <span>Chief Executive Officer</span>
                  <p>Leading Medix with a vision for accessible and affordable healthcare solutions.</p>
                  <div className="social">
                    <a href="#"><FaSquareXTwitter /></a>
                    <a href="#"><FaFacebook /></a>
                    <a href="#"><FaInstagram /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={200}>
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80" className="img-fluid" alt="Neha Sharma" />
                </div>
                <div className="member-info">
                  <h4>Rahul Sharma</h4>
                  <span>Product Manager</span>
                  <p>Overseeing the development and launch of innovative medical products.</p>
                  <div className="social">
                    <a href="#"><FaSquareXTwitter /></a>
                    <a href="#"><FaFacebook /></a>
                    <a href="#"><FaInstagram /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay={300}>
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80" className="img-fluid" alt="Vikram Singh" />
                </div>
                <div className="member-info">
                  <h4>Vikram Singh</h4>
                  <span>Chief Technology Officer</span>
                  <p>Driving technological advancements to enhance our healthcare solutions.</p>
                  <div className="social">
                    <a href="#"><FaSquareXTwitter /></a>
                    <a href="#"><FaFacebook /></a>
                    <a href="#"><FaInstagram /></a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mt-4" data-aos="zoom-in" data-aos-delay={400}>
              <div className="member d-flex align-items-start">
                <div className="pic">
                  <img src="https://images.unsplash.com/photo-1557862921-37829c790f19?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2071&q=80" className="img-fluid" alt="Aisha Mehta" />
                </div>
                <div className="member-info">
                  <h4>Ansh Mehta</h4>
                  <span>Accountant</span>
                  <p>Ensuring the financial health and accountability of Medix.</p>
                  <div className="social">
                    <a href="#"><FaSquareXTwitter /></a>
                    <a href="#"><FaFacebook /></a>
                    <a href="#"><FaInstagram /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Team
