import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from './Components/Navbar/Navbar'
import Products from "./Components/Products/Products";
import Home from "./Components/Home/Home";
import Services from "./Components/Services/Services";
import Career from "./Components/Career/Career";
import Contact from "./Components/Contact/Contact";


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Products />} />
          <Route path="/services" element={<Services />} />
          <Route path="/career" element={<Career />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
