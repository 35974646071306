import React from "react";
import "../Services/Services.css";
import Education from "./Education";
import Training from "./Training";
import Review from "../Review/Review"
import Youtube from "./Youtube";
import { Link } from "react-router-dom";
const Services = () => {
  return (
    <>
      <div className="poster-s">
        <img src="po.jpg" alt="Poster" className="poster-image-s" />
      </div>
      
      <Education/>
      <Training/>
      <Review/>
      <Youtube/>
      <div class="section-s">
        <h2>To Know more about our Services</h2>
        <p>We provide quality training and building opportunities in medical fields</p>
        <Link to="/contact">
        <button class="button">Click to Contact</button>
        </Link>
    </div>
    </>
  );
};

export default Services;
