import React from 'react'
import '../Career/Career.css'
import Job from './Job'
const Career = () => {
  return (
    <>
     <div className="poster-ca">
      <img 
        src="posterc2.jpg" 
        alt="Poster" 
        className="poster-image-ca" 
      />
    </div>
    
<Job/>
    </>
  )
}

export default Career