import React, { useState } from 'react';

const videos = [
  { id: 1, url: 'https://www.youtube.com/embed/ZhB5Oiwzx4I' },
  { id: 2, url: 'https://www.youtube.com/embed/CbDbyzxuK0M' },
  { id: 3, url: 'https://www.youtube.com/embed/5UTIfcJN9uo' },
  { id: 4, url: 'https://www.youtube.com/embed/TSBjey6hh20' },
];

const Youtube = () => {
  const [playing, setPlaying] = useState(null);

  const handlePlay = (id) => {
    setPlaying(id);
  };

  const handlePause = () => {
    setPlaying(null);
  };

  return (
    <section className='story-section'>
     <div class="ribbon7">Our Success Story</div>
    <div className="youtube-grid">
      {videos.map((video) => (
        <div key={video.id} className="youtube-card">
          <iframe
            width="100%"
            height="317"
            src={video.url}
            title={`YouTube Video ${video.id}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
         
        </div>
      ))}
    </div>
    </section>
  );
};

export default Youtube;
