import React, { useEffect, useState } from 'react';
import './Counter.css';

const useCounter = (end, duration) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const incrementTime = duration / end;
    const increment = () => {
      start += 1;
      setCount(start);
      if (start < end) {
        setTimeout(increment, incrementTime);
      }
    };
    increment();
  }, [end, duration]);

  return count;
};

const Counter = () => {
  const countCoffee = useCounter(999, 10000);
  const countCode = useCounter(10000, 10000);
  const countExperience = useCounter(6, 10000);
  const countProject = useCounter(12, 10000);

  return (
    <section id="statistic" className="statistic-section one-page-section">
      <div className="container">
        <div className="row text-center">
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <i className="fa fa-coffee fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countCoffee}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Medicines</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <i className="fa fa-code fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countCode}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Customers</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <i className="fa fa-clock-o fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countExperience}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Deliveries</p>
            </div>
          </div>
          <div className="col-xs-12 col-md-3">
            <div className="counter">
              <i className="fa fa-laptop fa-2x stats-icon" />
              <h2 className="timer count-title count-number">{countProject}+</h2>
              <div className="stats-line-black" />
              <p className="stats-text">Saving In Cr</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counter;
