import React from "react";
import { FaNetworkWired, FaTags, FaPills, FaTruckLoading, FaHandshake, FaFlask } from 'react-icons/fa';
import "../Services/Services.css";

const Education = () => {
  const education = [
    {
      icon: FaNetworkWired,
      title: "Building Professional Networks",
      description:
        "We facilitate the development of professional networks, ensuring healthcare professionals can collaborate and share knowledge across various regions.",
    },
    {
      icon: FaTags,
      title: "Affordable Training Programs",
      description:
        "Our training programs are competitively priced, making it feasible for all healthcare workers to access essential medical education.",
    },
    {
      icon: FaPills,
      title: "Innovative Medical Training",
      description:
        "We offer cutting-edge training on the latest medical innovations, helping healthcare professionals stay updated with new treatment methods and technologies.",
    },
    {
      icon: FaTruckLoading,
      title: "Efficient Supply Chain Management Training",
      description:
        "Our training includes optimizing supply chain operations to ensure healthcare providers can manage timely and reliable delivery of medical supplies.",
    },
    {
      icon: FaHandshake,
      title: "Strengthening Patient Relationships",
      description:
        "We emphasize the importance of building strong patient relationships, providing training on patient care and communication to foster trust and long-term care partnerships.",
    },
    {
      icon: FaFlask,
      title: "Advancing Medical Research Skills",
      description:
        "We invest in training that enhances research skills, ensuring healthcare professionals are equipped to contribute to advanced and effective medical research.",
    },
  ];

  return (
    <>
      <div className="education-container">
      <div class="ribbon6">Medix Offers Education</div>
        <div className="education-row">
          {education.map((education, index) => (
            <div className="edu-div">
            <div key={index} className="education-service">
              <education.icon className="education-icon" />
              <h2 className="education-service-title">{education.title}</h2>
              <p>{education.description}</p>
            </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Education;
