import React from 'react'
import '../Products/Products.css'
import { Link } from 'react-router-dom'
import ProductGrid from './Productgrid'
import ProductGrid2 from './Productsgrid2'
const Products = () => {
  return (
    <>
    <div className='main-products'>
  <div className="poster">
      <img 
        src="poster.jpg" 
        alt="Poster" 
        className="poster-image" 
      />
    </div>
    <div className='curr-div'>
    <div class="ribbon5">Our Current Products</div>
    </div>
  <ProductGrid/>
  <div className='second-p'>
  <div className='curr-div2'>
  <div class="ribbon5">Our Up-Coming Products</div>
    </div>
    <ProductGrid2/>
    <div class="section">
        <h2>Discover Premium Medical Products</h2>
        <p>High-quality supplies for all your healthcare needs.</p>
        <Link to="/contact">
        <button class="button">Click to Contact</button>
        </Link>
    </div>
    </div>
    </div>
    </>
  )
}

export default Products