
const products = [
    {
      id: 1,
      name: "Product 1",
      price: "₹50.00",
      image: "https://img.freepik.com/free-vector/sports-nutrition-bottle-composition_1284-23338.jpg?t=st=1718365396~exp=1718368996~hmac=85936604b77f74bc248252251595b306f6743867f80d35c7fae578c316234a96&w=740"
    },
    {
      id: 2,
      name: "Product 2",
      price: "₹75.00",
      image: "https://img.freepik.com/free-vector/cosmetic-products-realistic-advertisement-template_1284-23474.jpg?t=st=1718366148~exp=1718369748~hmac=78210e71dd0eb5a40183fe88a86faa35deaf14e464ee4222f1478b79c2871c76&w=740"
    },
    {
      id: 3,
      name: "Product 3",
      price: "₹100.00",
      image: "https://img.freepik.com/free-vector/sports-nutrition-bottle-composition_1284-23338.jpg?t=st=1718365396~exp=1718368996~hmac=85936604b77f74bc248252251595b306f6743867f80d35c7fae578c316234a96&w=740"
    },
    {
      id: 4,
      name: "Product 4",
      price: "₹125.00",
      image: "https://img.freepik.com/free-vector/cosmetic-products-realistic-advertisement-template_1284-23474.jpg?t=st=1718366148~exp=1718369748~hmac=78210e71dd0eb5a40183fe88a86faa35deaf14e464ee4222f1478b79c2871c76&w=740"
    },
    {
      id: 5,
      name: "Product 5",
      price: "₹150.00",
      image: "https://img.freepik.com/free-vector/sports-nutrition-bottle-composition_1284-23338.jpg?t=st=1718365396~exp=1718368996~hmac=85936604b77f74bc248252251595b306f6743867f80d35c7fae578c316234a96&w=740"
    },
    {
      id: 6,
      name: "Product 6",
      price: "₹175.00",
      image: "https://img.freepik.com/free-vector/cosmetic-products-realistic-advertisement-template_1284-23474.jpg?t=st=1718366148~exp=1718369748~hmac=78210e71dd0eb5a40183fe88a86faa35deaf14e464ee4222f1478b79c2871c76&w=740"
    },
    {
      id: 7,
      name: "Product 7",
      price: "₹60.00",
      image: "https://img.freepik.com/free-vector/sports-nutrition-bottle-composition_1284-23338.jpg?t=st=1718365396~exp=1718368996~hmac=85936604b77f74bc248252251595b306f6743867f80d35c7fae578c316234a96&w=740"
    },
    {
      id: 8,
      name: "Product 8",
      price: "₹80.00",
      image: "https://img.freepik.com/free-vector/cosmetic-products-realistic-advertisement-template_1284-23474.jpg?t=st=1718366148~exp=1718369748~hmac=78210e71dd0eb5a40183fe88a86faa35deaf14e464ee4222f1478b79c2871c76&w=740"
    },
   
  ];
  
  export default products;
  