import React from 'react';
import '../Career/Career.css'
import { Link } from 'react-router-dom';
const Job = () => {
  const jobListings = [
    {
      position: "Medical Assistant",
      type: "Full-time",
      description: "Assist healthcare professionals by performing administrative and clinical tasks to ensure the smooth operation of a medical office. Responsibilities include taking patient histories, preparing patients for examinations, and managing appointment schedules."
    },
    {
      position: "Registered Nurse",
      type: "Part-time",
      description: "Provide direct patient care in various healthcare settings. Duties include assessing patient health problems and needs, developing and implementing nursing care plans, and maintaining medical records. Requires a valid nursing license."
    },
    {
      position: "Medical Receptionist",
      type: "Full-time",
      description: "Greet and assist patients as they enter the medical facility. Responsibilities include answering phone calls, scheduling appointments, verifying insurance information, and maintaining patient records. Strong organizational and communication skills required."
    },
    {
      position: "Medical Billing Specialist",
      type: "Part-time",
      description: "Responsible for processing insurance claims, handling patient billing, and ensuring accurate and timely reimbursement for medical services. Requires knowledge of medical billing software, coding procedures, and insurance guidelines."
    },
    {
      position: "Pharmacy Technician",
      type: "Full-time",
      description: "Assist pharmacists in preparing and dispensing medications, maintaining medication inventory, and providing patient information. Requires certification as a pharmacy technician and knowledge of pharmaceutical terminology."
    },
    {
      position: "Clinical Laboratory Technician",
      type: "Part-time",
      description: "Conduct laboratory tests and procedures to assist in diagnosing, treating, and preventing diseases. Responsibilities include collecting samples, performing tests, and maintaining laboratory equipment. Requires certification and experience in a clinical laboratory setting."
    }
  ];
  
  return (
    <div className="job-container">
      <div className="advertisement">
        
        <div className="advertisement-content">
          <h2>Join Medix: Your Gateway to Medical Opportunities</h2>
          <p>We are looking for talented individuals to join our team and make a difference in healthcare. Explore the opportunities below and apply today!</p>
         <Link to="/contact"><button className="contact-button">Contact Us</button></Link> 
        </div>
        <div className="advertisement-image">
          <img src="idea.jpg" alt="Medix Advertisement" />
        </div>
      </div>
     <div className="custom-shape-divider-top-1718629176">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
    <path d="M1200 0L0 0 598.97 114.72 1200 0z" className="shape-fill" />
  </svg>
</div>

      <div className='job-head-div'>
      <div class="ribbon8">Current Job Opening</div>
      </div>
      <div className="job-listings">
       
        {jobListings.map((job, index) => (
          <div className="j-card" key={index}>
            <h3 className="job-position">Position: {job.position}</h3>
            <p className="job-type"><strong>Type:</strong> {job.type}</p>
            <p className="job-description"><strong>Description:</strong> {job.description}</p>
            <Link to="/contact">
            <button className="apply-button">Apply Now</button>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Job;
