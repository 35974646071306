// src/components/ProductGrid.js
import React from 'react';
import '../Products/Products.css'
import products from '../Products/Productsdata';
import ProductCard from '../Products/Productcard';

const ProductGrid = () => {
  return (
    <div className="product-grid">
      {products.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
};

export default ProductGrid;
