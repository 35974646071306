import React from 'react'
import '../Footer/Footer.css'
import { FaFacebook } from "react-icons/fa6";
import { FaInstagramSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaLinkedin } from "react-icons/fa";
const Footer = () => {
  return (
    <>
<footer className="footer">
  <div className="containerf">
    <div className="footer-content">
      <div className="footer-logo">
        Medix
      </div>
      <div className="footer-links">
        <ul className="footer-menu">
          <li><a href="/">Home</a></li>
          <li><a href="/products">Products</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/career">Careers</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>
      <div className="footer-social">
        <ul className="social-icons">
          <li><a href="#"><FaFacebook /></a></li>
          <li><a href="#"><FaSquareXTwitter /></a></li>
          <li><a href="#"><FaInstagramSquare /></a></li>
          <li><a href="#"><FaLinkedin /></a></li>
        </ul>
      </div>
    </div>
    <div className="footer-bottom">
    <p>© 2024 <a href="https://infogainsoft.com/" target="_blank">infogainsoft.com</a> - All rights reserved.</p>
    </div>
  </div>
</footer>

    </>
  )
}

export default Footer;
