
const products2 = [
    {
      id: 1,
      name: "Product 1",
      price: "₹50.00",
      image: "https://img.freepik.com/free-vector/realistic-vitamin-complex-package_52683-35667.jpg?t=st=1718365880~exp=1718369480~hmac=45088f9762f3e601f8a808e3e5532dd5705562567b2c7a3e0fef8ec18fbdb7bb&w=740"
    },
    {
      id: 2,
      name: "Product 2",
      price: "₹75.00",
      image: "https://img.freepik.com/free-psd/sport-supplements-bottle-mockup_1318-179.jpg?t=st=1718365684~exp=1718369284~hmac=62ff1db2b0a8f57de6b8a10f04ab6abb84ef737716035a12c186ec5f9affa3e9&w=740"
    },
    {
      id: 3,
      name: "Product 3",
      price: "₹100.00",
      image: "https://img.freepik.com/free-vector/realistic-vitamin-complex-package_52683-35667.jpg?t=st=1718365880~exp=1718369480~hmac=45088f9762f3e601f8a808e3e5532dd5705562567b2c7a3e0fef8ec18fbdb7bb&w=740"
    },
    {
      id: 4,
      name: "Product 4",
      price: "₹125.00",
      image: "https://img.freepik.com/free-psd/sport-supplements-bottle-mockup_1318-179.jpg?t=st=1718365684~exp=1718369284~hmac=62ff1db2b0a8f57de6b8a10f04ab6abb84ef737716035a12c186ec5f9affa3e9&w=740"
    },
    {
      id: 5,
      name: "Product 5",
      price: "₹150.00",
      image: "https://img.freepik.com/free-vector/realistic-vitamin-complex-package_52683-35667.jpg?t=st=1718365880~exp=1718369480~hmac=45088f9762f3e601f8a808e3e5532dd5705562567b2c7a3e0fef8ec18fbdb7bb&w=740"
    },
   
  ];
  
  export default products2;
  