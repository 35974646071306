import React,{useState} from 'react'
import '../Contact/Contact.css'
import { FaMapMarkerAlt, FaPhoneAlt, FaEnvelope, FaGlobe } from 'react-icons/fa';

const Contactmain = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    department: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };
    const cardData = [
        { id: 1, heading: 'Address', text: 'Sector 12B, Noida, India', icon: <FaMapMarkerAlt /> },
        { id: 2, heading: 'Mobile', text: '+1 234 567 890', icon: <FaPhoneAlt /> },
        { id: 3, heading: 'Email', text: 'example@example.com', icon: <FaEnvelope /> },
        { id: 4, heading: 'Website', text: 'www.example.com', icon: <FaGlobe /> },
    ];
  return (

    <>
    <div className='img-all'>
    <div className='all-con'>
    <div className='div-co-head'>
    <div class="ribbon9">Stay Connected</div>
    </div>
<div className="cards-container">
      {cardData.map((card) => (
        <div className="card" key={card.id}>
          <div className="card-icon">{card.icon}</div>
          <h2 className="card-heading">{card.heading}</h2>
          <p className="card-text">{card.text}</p>
        </div>
      ))}
    </div>
    <div className='div-tim'>
    <div className="card-t" key="additional-card-t">
          <h2 className="card-heading-t">Our Timing</h2>
          <h3 className="card-subheading-t">Mon - Sat</h3>
          <p className="card-paragraph-t">Morning 10:00 am to 01:00pm</p>
          <p className="card-paragraph-t">Evening 5:00 am to 9:00pm</p>
          <h3 className="card-subheading-t">Sunday</h3>
          <p className="card-paragraph-t">Morning: 10:00 am - 1:00 pm</p>
        </div>
        </div>
        </div>
        <div className="wrapper">
      <div className="left-con">
      <h3 className='c-head'>Contact Form</h3>
      <div className='form-div'>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone:</label>
            <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="department">Department:</label>
            <input type="text" id="department" name="department" value={formData.department} onChange={handleChange} className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} className="form-control" />
          </div>
          <button type="submit" className="bt-c">Send</button>
        </form>
        </div>
      </div>
      <div className="right-con">
      <div id="map" className="responsive-map">
        <iframe
          src="https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s6525+N,+Campbell+Ave,Chicago,+IL!6i13"
          width={600}
          height={650}
          frameBorder={0}
          style={{ border: 0 }}
          allowFullScreen
        />
      </div>
      </div>
    </div>
        </div>
    </>
  )
}

export default Contactmain