import React, { useState, useEffect } from 'react';
import '../Navbar/Navbar.css';

const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleMenuClick = () => {
        setIsActive(!isActive);
    };

    return (
        <nav className={`nav ${isScrolled ? 'affix' : ''}`}>
            <div className="container">
                <div className="logo">
                    <a href="/">Medix</a>
                </div>
                <div id="mainListDiv" className={`main_list ${isActive ? 'show_list' : ''}`}>
                    <ul className={`navlinks ${isActive ? 'active' : ''}`}>
                        <li><a href="/">Home</a></li>
                        <li><a href="/products">Products</a></li>
                        <li><a href="/services">Services</a></li>
                        <li><a href="/career">Careers</a></li>
                        <li><a href="/contact">Contact</a></li>
                    </ul>
                </div>
                <span className={`navTrigger ${isActive ? 'active' : ''}`} onClick={handleMenuClick}>
                    <i></i>
                    <i></i>
                    <i></i>
                </span>
            </div>
        </nav>
    );
};

export default Navbar;
