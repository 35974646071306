import React from 'react'
import '../Contact/Contact.css'
import Contactmain from './Contactmain'
const Contact = () => {
  return (
    <>
<div className="poster-co">
      <img 
        src="posterco.jpg" 
        alt="Poster" 
        className="poster-image-co" 
      />
    </div>
    <Contactmain/>
    </>
  )
}

export default Contact