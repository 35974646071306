import React from 'react';
import { MdArrowForward } from 'react-icons/md'; // Importing arrow icon from react-icons
import '../Services/Services.css'; // Import your CSS file for styling

const Training = () => {
  
    const cardsData = [
        {
          id: 1,
          title: 'Team Building Workshop',
          imageUrl: 't1.jpg',
          description: 'Learn effective strategies for building strong teams and fostering collaboration.',
          link: '#'
        },
        {
          id: 2,
          title: 'Leadership Training Program',
          imageUrl: 't2.jpg',
          description: 'Develop your leadership skills and inspire your team to achieve greater success.',
          link: '#'
        },
        {
          id: 3,
          title: 'Conflict Resolution Seminar',
          imageUrl: 't3.jpg',
          description: 'Master techniques to manage conflicts within teams and promote harmony.',
          link: '#'
        },
        {
          id: 4,
          title: 'Effective Communication Training',
          imageUrl: 't4.jpg',
          description: 'Enhance communication skills to ensure clarity and understanding among team members.',
          link: '#'
        },
        {
          id: 5,
          title: 'Team Bonding Retreat',
          imageUrl: 't5.jpg',
          description: 'Experience bonding activities designed to strengthen relationships and boost morale.',
          link: '#'
        },
        {
          id: 6,
          title: 'Project Management Workshop',
          imageUrl: 't6.jpg',
          description: 'Learn essential project management techniques to lead successful team projects.',
          link: '#'
        }
      ];

  return (
    <div className='training-section'>
    <div class="ribbon7">Team Building & Training</div>
    <div className="training-container">
      {cardsData.map(card => (
        <div key={card.id} className="t-card">
          <img src={card.imageUrl} alt={card.title} className="card-image" />
          <div className="card-content">
            <h3 className="card-title">{card.title}</h3>
            <p className="card-description">{card.description}</p>
            <a href={card.link} className="know-more-link">
              Know More <MdArrowForward className="arrow-icon" />
            </a>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default Training;
